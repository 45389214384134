import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import AddOrEditSchema from "./AddOrEditGameValidationSchema";

import {
  clearState,
  updateState,
} from "../../features/gamesItems/addOrEditGameItemSlice";
import {
  addGameItem,
  editGameItem,
} from "../../features/gamesItems/rememberGameItemsSlice";

import GamesDataService from "../../services/games-services";

function AddOrEditGame(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const add = useSelector((state) => state.addOrEditGameItem.add);
  const id = useSelector((state) => state.addOrEditGameItem.id);
  const category = useSelector((state) => state.addOrEditGameItem.category);
  const webURL = useSelector((state) => state.addOrEditGameItem.webURL);
  const iframeURL = useSelector((state) => state.addOrEditGameItem.iframeURL);
  const imageURL = useSelector((state) => state.addOrEditGameItem.imageURL);
  const name = useSelector((state) => state.addOrEditGameItem.name);

  const gameCollection = useSelector((state) => state.rememberGameItems);

  const submitForm = async (values) => {
    setIsLoading(true);

    const newGameItem = {
      name: values.name.trim(),
      imageURL: values.imageURL.trim(),
      iframeURL: values.iframeURL.trim(),
      webURL: values.webURL.trim(),
    };
    try {
      if (add === true) {
        console.log("adding");
        await GamesDataService.addGameItem(
          newGameItem,
          values.category,
          values.id
        );
        dispatch(
          addGameItem({
            newGame: newGameItem,
            category: values.category,
            id: values.id,
          })
        );
        dispatch(clearState());
      } else {
        console.log("editing");
        await GamesDataService.editGameItem(
          newGameItem,
          values.category,
          values.id
        );
        dispatch(
          editGameItem({
            newGame: newGameItem,
            category: values.category,
            id: values.id,
          })
        );
        // dispatch(clearState());
      }
      toast.success("Operatiunea s-a realizat cu succes!");
      setIsLoading(false);
      navigate("/games-items");
    } catch (e) {
      console.log(e);
      toast.error("S-a produs o eraore. Incearca din nou.");
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/games-items">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={"center"} size="xl" py="3" mb="5">
          {add ? "Adauga un item la jocuri" : "Editeaza jocul din aplicatie"}
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={"900px"}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              id: id.value,
              category: category.value,
              name: name.value,
              imageURL: imageURL.value,
              iframeURL: iframeURL.value,
              webURL: webURL.value,
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
            validationSchema={AddOrEditSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    isInvalid={errors.category && touched.category}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Select}
                      name="category"
                      isDisabled={add ? false : true}
                      value={values.category}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("category", e.target.value);
                      }}
                      placeholder=" "
                    >
                      {Object.keys(gameCollection).map((e, index) => {
                        return (
                          <option value={e}>{gameCollection[e].name}</option>
                        );
                      })}
                    </Field>
                    <FormLabel>Categorie joc</FormLabel>
                    {errors.category && touched.category ? (
                      <FormErrorMessage>{errors.category}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.id && touched.id}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      name="id"
                      as={Input}
                      isDisabled={add ? false : true}
                      value={values.id}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("id", e.target.value);
                      }}
                      placeholder=" "
                    ></Field>
                    <FormLabel>ID joc</FormLabel>
                    {errors.id && touched.id ? (
                      <FormErrorMessage>{errors.id}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.name && touched.name}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="name"
                      value={values.name}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("name", e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Nume joc</FormLabel>
                    {errors.name && touched.name ? (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.imageURL && touched.imageURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="imageURL"
                      value={values.imageURL}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("imageURL", e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>URL Imagine</FormLabel>
                    {errors.imageURL && touched.imageURL ? (
                      <FormErrorMessage>{errors.imageURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.webURL && touched.webURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="webURL"
                      value={values.webURL}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("webURL", e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>URL Web</FormLabel>
                    {errors.webURL && touched.webURL ? (
                      <FormErrorMessage>{errors.webURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>
                  <FormControl
                    isInvalid={errors.iframeURL && touched.iframeURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="iframeURL"
                      value={values.iframeURL}
                      onChange={(e) => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue("iframeURL", e.target.value);
                      }}
                      placeholder=" "
                    />
                    <FormLabel>URL iFrame</FormLabel>
                    {errors.iframeURL && touched.iframeURL ? (
                      <FormErrorMessage>{errors.iframeURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <Center mt={"4rem"}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={"200px"}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      {add ? "Adauga" : "Editeaza"}
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEditGame;
