import React from "react";
import { Navbar } from "../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import {
  Container,
  Box,
  Center,
  Heading,
  VStack,
  Spacer,
} from "@chakra-ui/react";

function Layout(props) {
  // const modifyUserCoinStats = async () => {
  //   await UsersDataService.getAllUsers();
  //   console.log('finished');
  // };
  return (
    <>
      {" "}
      <VStack h="100vh">
        <Navbar />
        <Box height={5} />
        <Outlet />
        <Spacer />
        <Spacer />
        <Box float="bottom" bg="gray.200" w="100%" p={4} color="gray">
          <Center>Păcănele Gratis App Dashboard</Center>
        </Box>
      </VStack>
    </>
  );
}

export default Layout;
