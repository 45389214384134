import {
  Heading,
  Container,
  Stack,
  Button,
  Box,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";

function Dashboards() {
  return (
    <>
      <Container maxW="lg" py="16" px="8">
        <Center>
          <Image
            boxSize="100px"
            objectFit="cover"
            src={logo}
            alt="Cazino 365"
          />
        </Center>

        <Stack spacing="8">
          <Stack spacing="6" textAlign="center">
            <Heading size="xl">Dashboard Pacanele Gratis App</Heading>
            <Text>
              Alege care colectie din baza de date doresti sa o editezi
            </Text>
          </Stack>
          <Box py="8" px="10" boxShadow="xl" borderRadius="md">
            <Stack spacing="6">
              <Link to="/promo-items">
                <Button width="100%" colorScheme="gray">
                  Carusel Promotii
                </Button>
              </Link>
              <Link to="/games-items">
                <Button width="100%" colorScheme="gray">
                  Jocuri
                </Button>
              </Link>
              <Link to="/casino-items">
                <Button width="100%" colorScheme="gray">
                  Cazinouri reale
                </Button>
              </Link>
              <Link to="/utilizatori">
                <Button width="100%" colorScheme="gray">
                  Utilizatori
                </Button>
              </Link>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}

export default Dashboards;
